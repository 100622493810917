@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  BODY,
  HTML {
    @apply text-gray-700 h-full;
  }
}
.h2 {
  @apply font-bold sm:text-4xl text-2xl;
}

.h3 {
  @apply font-bold sm:text-2xl text-xl;
}

.h4 {
  @apply font-bold text-xl;
}

.hint {
  @apply text-gray-500 text-xs italic;
}

.link {
  @apply text-wosm-default hover:text-wosm-dark underline;
}

.button {
  @apply inline-flex h-12 items-center justify-center gap-2 text-sm uppercase disabled:cursor-not-allowed rounded-md px-6 py-4 shadow-sm font-semibold text-white bg-wosm-default hover:bg-wosm-dark dark:hover:bg-wosm-light dark:hover:text-wosm-dark;
}

.minibutton {
  @apply inline-flex h-8 items-center gap-2 text-sm uppercase disabled:cursor-not-allowed rounded-md px-6 py-4 shadow-sm font-semibold text-white bg-wosm-default hover:bg-wosm-dark dark:hover:bg-wosm-light dark:hover:text-wosm-dark;
}

.altButton {
  @apply inline-flex items-center rounded-md bg-white px-3 py-2 text-sm border text-gray-700 border-gray-700 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600;
}

.pageTitle {
  @apply text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight;
}
.pageSubtitle {
  @apply text-xl/7 font-bold text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight;
}

.modalTitle {
  @apply font-bold text-gray-900 sm:truncate text-xl sm:tracking-tight;
}

th {
  @apply sticky whitespace-nowrap px-3 py-2.5 text-left font-semibold;
}

td {
  @apply whitespace-nowrap px-3 py-2;
}

/* .full-width {
  @apply -mx-4 sm:-mx-6 lg:-mx-8;
}

.full-width tr th:first-child {
  @apply pl-4 sm:pl-6 lg:pl-8;
}

.full-width tr td:last-child {
  @apply pr-4 sm:pr-6 lg:pr-8;
} */

div.card {
  @apply p-4 bg-white rounded-lg ring-1 ring-gray-200 ring-inset;
}

.input-text-style {
  @apply text-gray-900 leading-tight placeholder:text-gray-400;
}

.input-box-style {
  @apply focus:outline-none bg-gray-100 focus:bg-white;
  @apply appearance-none block w-full rounded py-3 px-4 border border-gray-100 focus:border-gray-500;
  @apply focus:ring-2 focus:ring-wosm-light;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.h3 {
  @apply my-4 text-xl font-semibold;
}

.no-records {
  @apply block rounded-md bg-gray-50 p-8 text-center text-sm text-gray-500;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.appear-animation {
  opacity: 0; /* Start hidden */
  animation: fadeIn 1s ease forwards; /* Duration and timing function */
}

.help-page p {
  @apply mb-4 text-sm text-pretty;
}

.help-page h2 {
  @apply font-semibold text-lg;
}
